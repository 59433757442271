import { ReactNode, useEffect, useMemo } from 'react';

import { useAppConfigContext } from '../appConfigContext';
import { FlagsContext, FlagsContextProperties } from './flagsContext';
import { trackOptimizelyEventClient } from './helpers/trackOptimizelyEventClient';
import { OptimizelyFeatureFlags } from '@tgg/common-types';
import { EventKey, dispatchEvent } from '@tgg/services';
import {
    CustomOptimizelyEventAttributes,
    FlagsConstants,
    OptimizelyEvent,
    TggFlagsObject,
    defaultFlags,
    getAppLogger,
    getOptimizelyUserIdClientSide,
    userIdentifierCookieApi,
} from '@tgg/util';

export type FlagsProviderProperties = {
    children: ReactNode;
    flagsContextData: {
        flags: TggFlagsObject;
        flagsUserId: string;
        pathname: string;
    };
};

export function FlagsProvider({
    children,
    flagsContextData,
}: FlagsProviderProperties) {
    const { flags, flagsUserId, pathname } =
        flagsContextData ?? /* istanbul ignore next */ {};

    const resolvedFlags = useMemo(
        () => ({ ...defaultFlags, ...flags }),
        [flags],
    );

    const {
        appConfig: { appIdentifier },
    } = useAppConfigContext();

    const contextValue: FlagsContextProperties = useMemo(() => {
        return {
            flags: resolvedFlags,
            flagsUserId,
            pathname,
            trackFlagsEvent: (
                optimizelyEvent: OptimizelyEvent,
                attributes?: CustomOptimizelyEventAttributes,
            ) => {
                /* istanbul ignore else */
                if (appIdentifier !== FlagsConstants.UNRESOLVED) {
                    const logger = getAppLogger({
                        applicationIdentifier: appIdentifier,
                    });
                    trackOptimizelyEventClient(
                        optimizelyEvent,
                        {
                            customAttributes:
                                attributes ?? /* istanbul ignore next */ {},
                            userId: flagsUserId,
                            pathname,
                        },
                        { logger },
                    );
                }
            },
        };
    }, [resolvedFlags, flagsUserId, pathname, appIdentifier]);

    useEffect(() => {
        /* istanbul ignore else */
        if (resolvedFlags)
            Object.keys(resolvedFlags).forEach(flagKey => {
                const flag = resolvedFlags[flagKey as keyof TggFlagsObject];
                // @ts-ignore
                if (flag.enabled && flag.variables?.enableDataLayer === true) {
                    dispatchEvent(EventKey.FLAG_DECISION_FX, {
                        'optimizely-flagKey': flagKey,
                        'optimizely-ruleKey': flag.ruleKey || 'root',
                        'optimizely-variationKey': flag.variationKey,
                        'optimizely-userId':
                            flagsUserId ??
                            /* istanbul ignore next */ FlagsConstants.UNRESOLVED,
                        'optimizely-resolvedPath': pathname,
                    });
                }
            });
    }, [resolvedFlags, flagsUserId, pathname]);

    useEffect(() => {
        if (
            resolvedFlags &&
            flagsUserId &&
            appIdentifier !== FlagsConstants.UNRESOLVED
        ) {
            const logger = getAppLogger({
                applicationIdentifier: appIdentifier,
            });

            const userIdFromCookie = getOptimizelyUserIdClientSide({
                logger,
                shouldReset: flagsUserId === FlagsConstants.UNRESOLVED,
                userRecognition:
                    resolvedFlags[OptimizelyFeatureFlags.FX_USER_RECOGNITION],
            });

            /* istanbul ignore else */
            if (
                flagsUserId !== userIdFromCookie &&
                flagsUserId !== FlagsConstants.UNRESOLVED
            )
                userIdentifierCookieApi.browser.setCookie(flagsUserId);
        }
    }, [flagsUserId, appIdentifier, resolvedFlags]);

    return (
        <FlagsContext.Provider value={contextValue}>
            {children}
        </FlagsContext.Provider>
    );
}
