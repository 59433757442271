import { AnyContext } from '../cookies';
import { getOptimizelyUserId } from './cookie/featureFlagsCookie';
import { type FeatureFlagsRequestOptions } from './featureFlags.types';
import { getFeatureFlagRequestOptions } from './helpers/featureFlagsHelpers';
import { optimizelyFlagsGetterTggWrapper } from './optimizely/optimizelyApi';
import { OptimizelyFeatureFlags } from '@tgg/common-types';

export const getAllFeatureFlagsSsr = async (
    context: AnyContext,
    options: FeatureFlagsRequestOptions,
) => {
    const { pathname } = getFeatureFlagRequestOptions(context);
    const userId = await getOptimizelyUserId(context, { ...options });

    const flagsObject = await optimizelyFlagsGetterTggWrapper('ALL', userId, {
        ...options,
        pathname,
    });

    return {
        flags: flagsObject.result,
        pathname,
        userId,
        error: flagsObject.error,
    };
};

export const getFeatureFlagSsr = async (
    context: AnyContext,
    key: OptimizelyFeatureFlags,
    options: FeatureFlagsRequestOptions,
) => {
    const { pathname } = getFeatureFlagRequestOptions(context);
    const userId = await getOptimizelyUserId(context, { ...options });

    const flagResult = await optimizelyFlagsGetterTggWrapper(key, userId, {
        ...options,
        pathname,
    });

    return {
        flag: flagResult.result,
        pathname,
        userId,
        error: flagResult.error,
    };
};
