import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import {
    AVAILABLE_COOKIES,
    AnyContext,
    getCookieSsr,
    getUserIdentifierCookieSsr,
    setUserIdentifierCookieSsr,
    userIdentifierCookieApi,
} from '../../cookies';
import { getCookie } from '../../cookies/cookies.browser.helpers';
import { defaultFlags } from '../defaultFlags';
import {
    FlagsConstants,
    FxUserRecognitionFlag,
    FxUserRecognitionVariations,
    type FeatureFlagsRequestOptions,
} from '../featureFlags.types';
import { optimizelyFlagsGetterTggWrapper } from '../optimizely/optimizelyApi';
import { type Nullable, OptimizelyFeatureFlags } from '@tgg/common-types';

const {
    publicRuntimeConfig: { APP_ENVIRONMENT },
} = getConfig();

export const resolveUserCorrlationId = (
    recognitionMode: FxUserRecognitionVariations,
    ids: {
        gaId: Nullable<string>;
        optiId: Nullable<string>;
        fallbackId: Nullable<string>;
    },
    options: {
        generateFallbackId?: boolean;
        useSecondaryId?: boolean;
    },
) => {
    const { fallbackId, gaId, optiId } = ids;
    const { generateFallbackId, useSecondaryId } = options;

    if (fallbackId === FlagsConstants.DEBUG) return FlagsConstants.DEBUG;
    if (!fallbackId && !gaId && !optiId && !generateFallbackId)
        return FlagsConstants.UNRESOLVED;
    if (!fallbackId && !gaId && !optiId && generateFallbackId)
        return uuidv4() as string;

    const newFallbackId =
        generateFallbackId || fallbackId === FlagsConstants.UNRESOLVED
            ? (uuidv4() as string)
            : FlagsConstants.UNRESOLVED;

    const primaryId =
        recognitionMode === 'GA'
            ? gaId
            : recognitionMode === 'OPTIMIZELY'
            ? optiId
            : fallbackId;

    const secondaryId = useSecondaryId
        ? recognitionMode === 'GA'
            ? optiId
            : recognitionMode === 'OPTIMIZELY'
            ? gaId
            : fallbackId
        : null;

    const resolvedId =
        primaryId ||
        secondaryId ||
        fallbackId ||
        // theoretically unreachable
        /* istanbul ignore next */ newFallbackId;

    return resolvedId === FlagsConstants.UNRESOLVED
        ? newFallbackId
        : resolvedId;
};

export const getOptimizelyUserId = async (
    context: AnyContext,
    options: FeatureFlagsRequestOptions,
) => {
    try {
        const { result } = await optimizelyFlagsGetterTggWrapper(
            OptimizelyFeatureFlags.FX_USER_RECOGNITION,
            FlagsConstants.ROOT,
            { pathname: '', logger: options.logger },
        );

        const recognitionMode = (result.variationKey ||
            /* istanbul ignore next */ defaultFlags[
                OptimizelyFeatureFlags.FX_USER_RECOGNITION
            ].variationKey) as FxUserRecognitionVariations;

        const generateFallbackId =
            result.variables?.generateFallbackId ??
            /* istanbul ignore next */ defaultFlags[
                OptimizelyFeatureFlags.FX_USER_RECOGNITION
            ].variables?.generateFallbackId;

        const useSecondaryId =
            result.variables?.useSecondaryId ??
            /* istanbul ignore next */ defaultFlags[
                OptimizelyFeatureFlags.FX_USER_RECOGNITION
            ].variables?.useSecondaryId;

        const gaUserIdFromCookie = getCookieSsr<string>({
            context,
            cookieKey: AVAILABLE_COOKIES.GA4_USER_ID,
        });

        const optiUserIdFromCookie = getCookieSsr<string>({
            context,
            cookieKey: AVAILABLE_COOKIES.OPTIMIZELY_USER_ID,
        });

        const fallbackUserIdFromCookie = getUserIdentifierCookieSsr(context);

        const resolvedId = resolveUserCorrlationId(
            recognitionMode,
            {
                fallbackId: fallbackUserIdFromCookie,
                gaId: gaUserIdFromCookie,
                optiId: optiUserIdFromCookie,
            },
            {
                generateFallbackId,
                useSecondaryId,
            },
        );

        if (
            !fallbackUserIdFromCookie ||
            fallbackUserIdFromCookie !== resolvedId
        )
            setUserIdentifierCookieSsr(context, resolvedId as string);

        return resolvedId;
    } catch (error) /* istanbul ignore next */ {
        options.logger.error(
            `Error getting Optimizely userId: ${(error as Error).message}`,
            {
                correlationId: 'root',
            },
        );
        return FlagsConstants.UNRESOLVED;
    }
};

export const getOptimizelyUserIdClientSide = (
    options: FeatureFlagsRequestOptions & {
        shouldReset?: boolean;
        userRecognition: FxUserRecognitionFlag;
    },
) => {
    try {
        const { variationKey: recognitionMode, variables } =
            options.userRecognition;
        const { generateFallbackId, useSecondaryId } =
            variables ?? /* istanbul ignore next */ {};

        const gaUserIdFromCookie = getCookie<string>({
            cookieKey: AVAILABLE_COOKIES.GA4_USER_ID,
        });

        const optiUserIdFromCookie = getCookie<string>({
            cookieKey: AVAILABLE_COOKIES.OPTIMIZELY_USER_ID,
        });

        const fallbackUserIdFromCookie =
            userIdentifierCookieApi.browser.getCookie();

        const resolvedId = resolveUserCorrlationId(
            recognitionMode as FxUserRecognitionVariations,
            {
                fallbackId: fallbackUserIdFromCookie,
                gaId: gaUserIdFromCookie,
                optiId: optiUserIdFromCookie,
            },
            {
                generateFallbackId,
                useSecondaryId,
            },
        );

        if (
            options.shouldReset &&
            (!fallbackUserIdFromCookie ||
                fallbackUserIdFromCookie !== resolvedId)
        )
            userIdentifierCookieApi.browser.setCookie(resolvedId);

        return resolvedId;
    } catch (error) /* istanbul ignore next */ {
        options.logger.error(
            `Error getting Optimizely userId: ${(error as Error).message}`,
            {
                correlationId: 'root',
            },
        );
        return FlagsConstants.UNRESOLVED;
    }
};
