/* istanbul ignore file */

import { createGlobalStyle } from 'styled-components';

import { palette } from './theme';
import { Theme } from './theme.types';

interface StyledGlobalProperties {
    $isMaintenancePage?: boolean;
    theme: Theme;
}

export const Global = createGlobalStyle<StyledGlobalProperties>`
    html {
        font-size: 62.5%;
        height: ${properties => (properties.$isMaintenancePage ? '100vh' : '')};
        scroll-padding-top: 8rem;
    }

    body {
        font-weight: normal;
        background-color: ${palette.common.white};
        height: ${properties => (properties.$isMaintenancePage ? '100vh' : '')};

    }

    button {
        font-family: inherit;
    }

    div#__next,
    div#__next > div {
        height: ${properties => (properties.$isMaintenancePage ? '100vh' : '')};
    }

    p {
        strong {
            font-family: thegymgroupsans-Headline
        }

        em {
            font-family: thegymgroupsans-BoldItalic
        }
    }

    .pca {
        .pcaautocomplete {
            width: 31.5rem;
            margin-left: -2.6rem;
            border: none;
            border-radius: 2.5rem;
            font-size: 14px;

            ${({ theme }: { theme: Theme }) =>
                theme.breakpoints.up('desktop')} {
                width: 41rem;
            }

            .pcalist {
                border-style: solid;
                border-width: 0.1rem;
                border-radius: 2.5rem;
                border-color: inherit;
            }

            .pcaitem {
                border-bottom: 1px solid lightgrey;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 1.3rem 1.5rem;

                &.pcalastitem {
                    border: none;
                }
            }
        }
    }

    .visually-hidden {
        position: absolute;
        width: 0.1rem;
        height: 0.1rem;
        overflow: hidden;
        white-space: nowrap;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
    }
`;
