import { type OptimizelyDecision } from '@optimizely/optimizely-sdk';

import { defaultFlags } from '../defaultFlags';
import {
    type FeatureFlagsRequestOptions,
    TggFlagsObject,
    FlagsConstants,
} from '../featureFlags.types';
import {
    mapOptimizelyFlags,
    mapOptimizelyFlag,
} from '../helpers/featureFlagsHelpers';
import { initOptimizelyClient } from './initOptimizelyClient';
import { type OptimizelyFeatureFlags } from '@tgg/common-types';

type FlagsGetterInput = OptimizelyFeatureFlags | 'ALL';

type FlagsGetterOutput<T extends FlagsGetterInput> = T extends 'ALL'
    ? Record<OptimizelyFeatureFlags, OptimizelyDecision>
    : T extends OptimizelyFeatureFlags
    ? OptimizelyDecision
    : never;

type FlagsGetterWrapperOutput<T extends FlagsGetterInput> = T extends 'ALL'
    ? TggFlagsObject
    : T extends OptimizelyFeatureFlags
    ? TggFlagsObject[T]
    : never;

type FlagGetterOptions = FeatureFlagsRequestOptions & {
    pathname: string;
};

export const optimizelyFlagsGetter = async <T extends FlagsGetterInput>(
    flag: T,
    userId: string,
    options: FlagGetterOptions,
): Promise<{ result: FlagsGetterOutput<T> | null; error: null | unknown }> => {
    const { logger, pathname } = options;
    try {
        const optimizelyClient = await initOptimizelyClient(userId, logger, {
            pathname,
        });
        const { client, pid } = optimizelyClient;

        if (userId === FlagsConstants.DEBUG)
            logger.info(`Getting Optimizely flags with client: ${pid}`, {
                correlationId: userId,
            });

        const flagsObject =
            flag === 'ALL'
                ? await client.decideAll()
                : await client.decide(flag);

        if (!flagsObject)
            throw new Error('Optimizely returned null flags object');

        return { result: flagsObject as FlagsGetterOutput<T>, error: null };
    } catch (error) /* istanbul ignore next */ {
        logger.error(
            `Error getting Optimizely flags: ${(error as Error).message}`,
            {
                correlationId: 'root',
            },
        );
        return { result: null, error };
    }
};

export const optimizelyFlagsGetterTggWrapper = async <
    T extends FlagsGetterInput,
>(
    flag: T,
    userId: string,
    options: FlagGetterOptions,
): Promise<{
    result: FlagsGetterWrapperOutput<T>;
    error: null | unknown;
}> => {
    const { logger } = options;
    const defaultResult =
        flag === 'ALL'
            ? defaultFlags
            : defaultFlags[flag as OptimizelyFeatureFlags];

    const flagsObject = await optimizelyFlagsGetter(flag, userId, options);

    try {
        const mapper = flag === 'ALL' ? mapOptimizelyFlags : mapOptimizelyFlag;

        if (!flagsObject.result)
            return {
                result: defaultResult as FlagsGetterWrapperOutput<T>,
                error: flagsObject.error,
            };

        return {
            result: mapper(
                flagsObject.result as any,
            ) as FlagsGetterWrapperOutput<T>,
            error: null,
        };
    } catch (error) /* istanbul ignore next */ {
        logger.error(
            `Error getting Optimizely flags: ${(error as Error).message}`,
            {
                correlationId: 'root',
            },
        );
        return { result: defaultResult as FlagsGetterWrapperOutput<T>, error };
    }
};
